import React, { useRef, useEffect, useState } from "react";
import DefaultCoralCarrusel from "../../individual/DefaultCoralCarrusel/DefaultCoralCarrusel";
import SectionHeader from "../../individual/SectionHeader/SectionHeader";
import "./DefaultCoral.css";
import { IoPerson } from "react-icons/io5";
import useElementHeight from "../../../custom-hooks/useElementHeight";
import Carusel from "../../individual/Carusel/Carusel";
import Agenda from "../Agenda/Agenda";
import useIsMobile from "../../../custom-hooks/useIsMobile";

const DefaultCoral = ({
  logo,
  title,
  textBox,
  images,
  agendaData,
  defaultCoral,
  width,
  slides,
  homeImages,
  section,
  icon1Info,
  icon2Info,
  icon3Info,
  icon4Info,
  homeSubtitle,
  sectionHeaderTrajectoriaSubtitle,
  sectionHeaderTrajectoriaTitle,
  sectionHeaderProjectesRealitzatsTitle,
  sectionHeaderProjectesRealitzatsSubtitle,
  sectionHeaderAgendaTitle,
  sectionHeaderAgendaSubtitle,
  filter,
}) => {
  const refLogo = useRef(null);
  const refBox = useRef(null);
  const headerHeight = useElementHeight(refLogo);
  //eslint-disable-next-line
  const boxHeight = useElementHeight(refBox);
  const ref = useRef(null);
  const isMobile = useIsMobile();
  const [filteredImages, setFilteredImages] = useState();
  const dadesFiltrades = Object.fromEntries(
    Object.entries(images).filter(([key, value]) => {
      return (
        value.titol !== "" &&
        value.ubicacio !== "" &&
        value.image &&
        Array.isArray(value.image) &&
        value.image.length > 0
      );
    })
  );
  useEffect(() => {
    setFilteredImages(dadesFiltrades);
    //eslint-disable-next-line
  }, [images]);
  return (
    <div className="default-coral__container">
      <div className="default-coral__carrusel">
        <DefaultCoralCarrusel
          images={homeImages}
          title={title}
          subtitle={homeSubtitle}
        />
      </div>
      <div className="default-coral__trajectoria__container">
        <div
          className="default-coral__trajectoria__header-wrapper"
          id="default-coral__trajectoria__header-wrapper"
        >
          <SectionHeader
            title={sectionHeaderTrajectoriaTitle}
            width={"150px"}
            text={sectionHeaderTrajectoriaSubtitle}
            ref={refLogo}
          />
          {logo !== undefined && logo !== "" && (
            <img
              src={logo[0].url}
              alt=""
              className="default-coral__logo"
              style={{ height: `${headerHeight.height}px` }}
            />
          )}
        </div>
        <div className="default-coral__trajectoria__wrapper">
          <div className="default-coral__trajectoria__text-box" ref={refBox}>
            <div className="default-coral__trajectoria__text-box__title-wrapper">
              <span className="default-coral__trajectoria__text-box__subtitle">
                {section}
              </span>
              <span className="default-coral__trajectoria__text-box__title">
                {title}
              </span>
            </div>

            <p className="default-coral__trajectoria__text-box__info">
              {textBox}
            </p>
            <div className="default-coral__trajectoria__text-box__icon-wrapper">
              {icon1Info !== "" && icon1Info !== undefined && (
                <div
                  style={{
                    display: "flex",
                    gap: "14px",
                    alignItems: "center",
                  }}
                >
                  <IoPerson color="white" size={20} />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Lato, sans-serif",
                      fontSize: "20px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "30px",
                    }}
                  >
                    {icon1Info}
                  </span>
                </div>
              )}
              {icon2Info !== "" && icon2Info !== undefined && (
                <div
                  style={{
                    display: "flex",
                    gap: "14px",
                    alignItems: "center",
                  }}
                >
                  <IoPerson color="white" size={20} />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Lato, sans-serif",
                      fontSize: "20px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "30px",
                    }}
                  >
                    {icon2Info}
                  </span>
                </div>
              )}
              {icon3Info !== "" && icon3Info !== undefined && (
                <div
                  style={{
                    display: "flex",
                    gap: "14px",
                    alignItems: "center",
                  }}
                >
                  <IoPerson color="white" size={20} />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Lato, sans-serif",
                      fontSize: "20px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "30px",
                    }}
                  >
                    {icon3Info}
                  </span>
                </div>
              )}
              {icon4Info !== "" && icon4Info !== undefined && (
                <div
                  style={{
                    display: "flex",
                    gap: "14px",
                    alignItems: "center",
                  }}
                >
                  <IoPerson color="white" size={20} />
                  <span
                    style={{
                      color: "white",
                      fontFamily: "Lato, sans-serif",
                      fontSize: "20px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "30px",
                    }}
                  >
                    {icon4Info}
                  </span>
                </div>
              )}
            </div>
          </div>
          {/* <div
            className="default-coral__trajectoria__images-wrapper"
            style={{ height: boxHeight.height }}
          >
            {boxImages !== undefined &&
              boxImages.map((item, index) => {
                let style = {};
                switch (index) {
                  case 0:
                    style = {
                      width: "55%",
                      height: "52%",
                      left: 0,
                      top: "3.5%",
                    };
                    break;
                  case 1:
                    style = {
                      width: "40%",
                      height: "48%",
                      top: 0,
                      right: 0,
                    };
                    break;
                  case 2:
                    style = {
                      width: "45%",
                      height: "35%",
                      bottom: "3.5%",
                      left: "10%",
                    };
                    break;
                  case 3:
                    style = {
                      width: "33%",
                      height: "23%",
                      bottom: 0,
                      right: "8%",
                    };
                    break;
                  default:
                    break;
                }
                if (item !== undefined) {
                  return (
                    <img src={item.url} key={index} alt="coral" style={style} />
                  );
                }
                return null; // R
              })}
          </div> */}
        </div>
      </div>
      <div className="default-coral__projects__container">
        <div
          style={
            !isMobile
              ? { padding: "130px 150px", paddingBottom: "80px" }
              : { padding: "2rem", paddingBottom: "50px" }
          }
        >
          <SectionHeader
            width="350px"
            title={sectionHeaderProjectesRealitzatsTitle}
            text={sectionHeaderProjectesRealitzatsSubtitle}
            carrusel={true}
            coral={true}
          />
        </div>

        <div
          style={
            !isMobile
              ? {
                  overflow: "hidden",
                  paddingLeft: "130px",
                  paddingRight: "130px",
                }
              : { padding: 0, position: "relative" }
          }
          ref={ref}
        >
          <Carusel width={width} slides={slides} images={filteredImages} />
        </div>
      </div>
      <div className="default-coral__events__container">
        <SectionHeader
          title={sectionHeaderAgendaTitle}
          text={sectionHeaderAgendaSubtitle}
          width="280px"
        />
        <div style={isMobile ? { marginTop: "40px" } : {}}>
          <Agenda
            data={agendaData}
            defaultCoral={defaultCoral}
            sectionHeaderAgendaSubtitle={sectionHeaderAgendaSubtitle}
            sectionHeaderAgendaTitle={sectionHeaderAgendaTitle}
            filter={filter}
          />
        </div>
      </div>
    </div>
  );
};

export default DefaultCoral;
